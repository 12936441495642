import React, { useEffect, useContext, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { UserContext } from "./UserContext";
import { supabase } from "./SupabaseClient";
import {
  handleUpload,
  resizeAndCompressImage,
  deleteProfileImageFromStorage,
} from "./imageUtils";

const GeneralSettings = () => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [selectedFile, setSelectedFile] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState(null);
  const [city, setCity] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const { updateUser } = useContext(UserContext);
  const { t } = useTranslation();
  const [user, setUser] = useState({});

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  useEffect(() => {
    const getUser = async () => {
      const { data, error } = await supabase
        .from("Users")
        .select("*")
        .eq("id", currentUser.sub);

      if (data) {
        setFirstName(data[0].first_name);
        setLastName(data[0].last_name);
        if (data[0].city) {
          setCity(data[0].city);
        }
        if (data[0].profile_picture) {
          setAvatarSrc(data[0].profile_picture);
        }
      }
    };
    getUser();
  }, []);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const resizedBlob = await resizeAndCompressImage(
      file,
      2048,
      2048,
      0.9,
      true
    );
    const localUrl = URL.createObjectURL(resizedBlob);
    setAvatarSrc(localUrl);
    const imageObject = {
      content: URL.createObjectURL(file),
      id: file.name,
      isNew: true,
    };
    setSelectedFile(imageObject);
  };

  const handleImageUpdate = async () => {
    const newImageUrl = await handleUpload({
      images: [selectedFile],
      profileImage: currentUser.sub,
    });
    if (user.profile_picture) {
      await deleteProfileImageFromStorage(user.profile_picture);
    }

    const { error: updateError } = await supabase
      .from("Users")
      .update({ profile_picture: newImageUrl[0] })
      .eq("id", currentUser.sub);

    if (updateError) {
      throw updateError;
    }

    setSelectedFile(null);
    setAvatarSrc(newImageUrl[0]);
    updateUser({ profile_picture: newImageUrl[0] });
  };

  const cancelUpload = () => {
    setAvatarSrc(user.profileImageUrl);
    setSelectedFile(null);
  };

  const handleSave = async () => {
    const { data, error } = await supabase
      .from("Users")
      .update({
        first_name: firstName,
        last_name: lastName,
        city: city ? city : null,
      })
      .eq("id", currentUser.sub);
  };

  return (
    <Container className="settings-container">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Avatar
          sx={{ width: "150px", height: "150px" }}
          alt={firstName}
          src={avatarSrc}
        />

        {selectedFile ? (
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                color: "#000000 !important",
                backgroundColor: "#ffffff !important",
                borderRadius: "14px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                fontWeight: "300",
                fontSize: "10px",
              }}
              variant="contained"
              onClick={handleImageUpdate}
            >
              {t("save")}
            </Button>
            <Button
              sx={{
                color: "#000000 !important",
                backgroundColor: "#ffffff !important",
                borderRadius: "14px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
                fontWeight: "300",
                fontSize: "10px",
              }}
              variant="contained"
              onClick={cancelUpload}
            >
              {t("cancel")}
            </Button>
          </Box>
        ) : (
          <Button
            sx={{
              color: "#000000 !important",
              backgroundColor: "#ffffff !important",
              borderRadius: "14px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 1px",
              fontWeight: "300",
              fontSize: "10px",
            }}
            variant="contained"
          >
            {t("uploadPhoto")}
            <VisuallyHiddenInput
              type="file"
              onChange={handleFileChange}
              accept="image/*"
            />
          </Button>
        )}
        <Grid container spacing={3} sx={{ maxWidth: "500px" }}>
          <Grid item xs={3}>
            <div className="title">{t("firstName")}</div>
          </Grid>
          <Grid item xs={9} sx={{ textAlign: "left" }}>
            <TextField
              sx={{ width: "100%" }}
              value={firstName || ""}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <div className="title">{t("lastName")}</div>
          </Grid>
          <Grid item xs={9} sx={{ textAlign: "left" }}>
            <TextField
              sx={{ width: "100%" }}
              value={lastName || ""}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <div className="title">{t("city")}</div>
          </Grid>
          <Grid item xs={9} sx={{ textAlign: "left" }}>
            <TextField
              sx={{ width: "100%" }}
              size="small"
              variant="outlined"
              placeholder="Enter City"
              value={city || ""}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={9} sx={{ textAlign: "right" }}>
            <Button
              sx={{ padding: "2px 2px", fontWeight: "300", fontSize: "12px" }}
              variant="contained"
              onClick={() => {
                handleSave();
              }}
            >
              {t("save")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default GeneralSettings;
