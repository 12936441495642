import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { supabase } from "./SupabaseClient";
import {
  Box,
  Grid,
  Container,
  Typography,
  IconButton,
  Paper,
  InputBase,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Autocomplete,
  TextField,
  Avatar,
  DialogContentText,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  Send as SendIcon,
  AttachFile as AttachFileIcon,
} from "@mui/icons-material";
import MessageContainer from "./MessageContainer";
import dayjs from "dayjs";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Group as GroupIcon,
} from "@mui/icons-material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";

const Chat = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [message, setMessage] = useState("");
  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [threads, setThreads] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [openGroupDialog, setOpenGroupDialog] = useState(false);
  const [groupMembers, setGroupMembers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUserToAdd, setSelectedUserToAdd] = useState(null);
  const [currentThread, setCurrentThread] = useState(
    useState({ participants: [] })
  );
  const [isRemoveMemberDialogOpen, setIsRemoveMemberDialogOpen] =
    useState(false);
  const [memberIdToRemove, setMemberIdToRemove] = useState(null);
  const [memberNameToRemove, setMemberNameToRemove] = useState("");
  const { t } = useTranslation();
  const [mobileView, setMobileView] = useState("threads"); // Possible values: "threads" or "chat"

  // Assume you have all users in allUsers state

  useEffect(() => {
    const recipientId = state?.recipientId;

    if (recipientId) {
      handleThreadCheckOrCreate(recipientId).then(() => {
        setMessages([]);
        // Clear the state after handling the recipientId
        navigate(".", { replace: true, state: {} });
      });
    }
  }, [state, navigate]);

  const handleThreadCheckOrCreate = async (recipientId) => {
    try {
      // Step 1: Find group_ids where the recipient is a member
      const { data: recipientGroups, error: recipientGroupsError } =
        await supabase
          .from("GroupMembers")
          .select("group_id")
          .eq("user_id", recipientId);

      if (recipientGroupsError) {
        setError("Error fetching recipient's groups.");
        console.error(recipientGroupsError);
        return;
      }

      const recipientGroupIds = recipientGroups.map((group) => group.group_id);

      // Step 2: Find common group_ids where both users are members
      const { data: commonGroups, error: commonGroupsError } = await supabase
        .from("GroupMembers")
        .select("group_id")
        .eq("user_id", currentUser.sub)
        .in("group_id", recipientGroupIds);

      if (commonGroupsError) {
        setError("Error fetching common groups.");
        console.error(commonGroupsError);
        return;
      }

      // Step 3: For each common group, check if it has exactly 2 participants (a 1:1 chat)
      let existingThreadId = null;
      for (const group of commonGroups) {
        const { data: participants, error: participantsError } = await supabase
          .from("GroupMembers")
          .select("user_id")
          .eq("group_id", group.group_id);

        if (participantsError) {
          setError("Error fetching group participants.");
          console.error(participantsError);
          return;
        }

        if (participants.length === 2) {
          existingThreadId = group.group_id;
          break;
        }
      }

      if (existingThreadId) {
        // Use the existing thread
        setCurrentThreadId(existingThreadId);
      } else {
        // Create a temporary thread in the UI, without persisting in the database
        const temporaryThread = {
          groupId: `temp-${Date.now()}`, // Temporary ID for the thread
          groupName: `Chat with ${recipientId}`,
          participants: [
            {
              user_id: recipientId,
              first_name: "New Thread",
              profile_picture: "", // Can be updated later with real data
            },
          ],
          latestMessage: null,
          unreadCount: 0,
        };

        setThreads((prevThreads) => [temporaryThread, ...prevThreads]);
        setCurrentThreadId(temporaryThread.groupId); // Temporary thread ID
        setCurrentThread(temporaryThread);
      }
    } catch (error) {
      setError("An error occurred while checking or creating a thread.");
      console.error("Error in thread creation/check:", error);
    }
  };

  const fetchAllUsers = async () => {
    const { data, error } = await supabase
      .from("Followers")
      .select(
        `
        following_id,
        Users:following_id (id, first_name, last_name, profile_picture)
      `
      )
      .eq("follower_id", currentUser.sub);

    if (error) {
      console.error("Error fetching followed users:", error);
    } else {
      // Extract the user information from the joined Users table
      const usersFollowed = data.map((item) => item.Users);

      setAllUsers(usersFollowed);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchThreads = async () => {
    const { data, error } = await supabase
      .from("GroupMembers")
      .select(
        `
        group_id,
        last_read,
        Groups (
          name,
          GroupMembers!inner (
            user_id,
            Users (
              first_name,
              profile_picture
            )
          ),
          Messages (
            content,
            created_at,
            sender_id
          )
        )
      `
      )
      .eq("user_id", currentUser.sub);

    if (error) {
      console.error("Error fetching threads:", error);
    } else {
      const threadsData = data.map((groupMember) => {
        const group = groupMember.Groups;

        // Get participants excluding the current user
        const participants = group.GroupMembers.map((member) => ({
          user_id: member.user_id,
          first_name: member.Users.first_name,
          profile_image_url: member.Users.profile_picture,
        }));

        // Find the latest message
        let latestMessage = null;
        if (group.Messages && group.Messages.length > 0) {
          latestMessage = group.Messages.reduce((latest, message) => {
            return new Date(message.created_at) > new Date(latest.created_at)
              ? message
              : latest;
          }, group.Messages[0]);
        }

        // Calculate unread count
        const unreadCount = group.Messages.filter(
          (message) =>
            new Date(message.created_at) > new Date(groupMember.last_read)
        ).length;

        return {
          groupId: groupMember.group_id,
          groupName: group.name,
          participants,
          latestMessage,
          unreadCount, // Store the unread count
        };
      });

      // Sort threads by latest message timestamp (most recent first)
      threadsData.sort((a, b) => {
        const aTime = a.latestMessage
          ? new Date(a.latestMessage.created_at)
          : new Date(0);
        const bTime = b.latestMessage
          ? new Date(b.latestMessage.created_at)
          : new Date(0);
        return bTime - aTime;
      });

      setThreads(threadsData);

      // Set the most recent thread as the current thread
      if (threadsData.length > 0) {
        setCurrentThreadId(threadsData[0].groupId);
        setCurrentThread(threadsData[0]);
        if (!isMobile) {
          handleChangeThread(threadsData[0].groupId);
        }
      }
      return threadsData;
    }
  };

  const fetchMessages = async (threadId) => {
    const { data, error } = await supabase
      .from("Messages")
      .select(
        `
        id,
        content,
        created_at,
        sender_id,
        Users (
          id,
          first_name,
          last_name,
          profile_picture
        )
      `
      )
      .eq("group_id", threadId)
      .order("created_at", { ascending: true });

    if (error) {
      console.error("Error fetching messages:", error);
    } else {
      setMessages(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, mobileView]);

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const sendMessage = async () => {
    if (message.trim() === "") return;

    let threadId = currentThreadId;

    // If this is a temporary thread, create it in the database first
    if (currentThreadId.startsWith("temp-")) {
      try {
        const { data: newGroup, error: groupError } = await supabase
          .from("Groups")
          .insert([
            {
              name: `Chat between ${currentUser.sub} and ${currentThread.participants[0].user_id}`,
            },
          ])
          .select();

        if (groupError || !newGroup) {
          setError("Error creating new chat group.");
          console.error(groupError);
          return;
        }

        threadId = newGroup[0].id; // Use the real group ID

        // Add the participants to the GroupMembers table
        const { error: memberError } = await supabase
          .from("GroupMembers")
          .insert([
            { user_id: currentUser.sub, group_id: threadId },
            {
              user_id: currentThread.participants[0].user_id,
              group_id: threadId,
            },
          ]);

        if (memberError) {
          setError("Error adding members to the group.");
          console.error(memberError);
          return;
        }

        // Update the thread in the threads state with the new groupId
        // setThreads((prevThreads) =>
        //   prevThreads.map((thread) =>
        //     thread.groupId === currentThreadId
        //       ? { ...thread, groupId: threadId } // Replace temp ID with real groupId
        //       : thread
        //   )
        // );
        fetchThreads();
        setCurrentThreadId(threadId);
      } catch (error) {
        setError("An error occurred while creating the group.");
        console.error("Error creating new group:", error);
        return;
      }
    }

    const newMessage = {
      content: message,
      group_id: threadId, // Use the real or temporary thread ID
      created_at: new Date().toISOString(),
    };

    const { error } = await supabase.from("Messages").insert([newMessage]);

    if (error) {
      setError("Error sending message.");
      console.error("Error sending message:", error);
    } else {
      setMessage("");
    }

    await supabase
      .from("GroupMembers")
      .update({ last_read: new Date().toISOString() })
      .eq("user_id", currentUser.sub)
      .eq("group_id", currentThreadId);
  };

  const handleChangeThread = async (threadId) => {
    const selectedThread = threads.find(
      (thread) => thread.groupId === threadId
    );

    // Update the last read timestamp for the current user in this thread
    const { error } = await supabase
      .from("GroupMembers")
      .update({ last_read: new Date().toISOString() })
      .eq("group_id", threadId)
      .eq("user_id", currentUser.sub);

    if (error) {
      console.error("Error updating last read timestamp:", error);
    }

    // Reset unread count for the opened thread
    setThreads((prevThreads) =>
      prevThreads.map((thread) =>
        thread.groupId === threadId ? { ...thread, unreadCount: 0 } : thread
      )
    );

    // For mobile, switch view to chat when a thread is selected
    if (isMobile) {
      setMobileView("chat");
    }

    // Clear old messages before fetching new ones
    setMessages([]);

    // Set the new current thread and ID
    setCurrentThread(selectedThread);
    setCurrentThreadId(threadId);

    setLoading(true);
    if (threadId.startsWith("temp-")) {
      setLoading(false);
      return;
    }
    fetchMessages(threadId);
  };

  const channelsRef = useRef([]);
  const currentThreadIdRef = useRef(currentThreadId); // Create a ref for currentThreadId

  useEffect(() => {
    currentThreadIdRef.current = currentThreadId;
  }, [currentThreadId]);

  const handleNewMessage = useCallback(
    async (payload, threadId) => {
      try {
        const { data: senderData, error: senderError } = await supabase
          .from("Users")
          .select("id, first_name, last_name, profile_picture")
          .eq("id", payload.new.sender_id)
          .single();

        if (senderError) throw senderError;

        const newMessage = {
          ...payload.new,
          Users: senderData,
        };

        setThreads((prevThreads) => {
          const updatedThreads = prevThreads.map((thread) => {
            const isMessageFromCurrentUser =
              newMessage.sender_id === currentUser.sub;

            let unreadCount;
            if (
              !isMessageFromCurrentUser &&
              thread.groupId !== currentThreadIdRef.current
            ) {
              unreadCount = (thread.unreadCount || 0) + 1;
            } else if (isMobile && mobileView === "threads") {
              unreadCount = (thread.unreadCount || 0) + 1;
            } else {
              unreadCount = thread.unreadCount;
            }
            return thread.groupId === threadId
              ? {
                  ...thread,
                  latestMessage: newMessage,
                  unreadCount,
                }
              : thread;
          });

          return updatedThreads.sort((a, b) => {
            const aTime = a.latestMessage
              ? new Date(a.latestMessage.created_at)
              : new Date(0);
            const bTime = b.latestMessage
              ? new Date(b.latestMessage.created_at)
              : new Date(0);
            return bTime - aTime;
          });
        });

        if (threadId === currentThreadIdRef.current) {
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        }
      } catch (error) {
        console.error("Error processing new message:", error);
      }
    },
    [currentUser.sub] // Add currentUser.sub to dependencies for accuracy
  );

  const subscribeToThread = useCallback(
    (thread) => {
      const channel = supabase
        .channel(`public:Messages:group_id=eq.${thread.groupId}`)
        .on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table: "Messages",
            filter: `group_id=eq.${thread.groupId}`,
          },
          (payload) => handleNewMessage(payload, thread.groupId)
        )
        .subscribe();

      return channel;
    },
    [handleNewMessage]
  );

  useEffect(() => {
    const setupSubscriptions = async () => {
      try {
        const fetchedThreads = await fetchThreads();

        // Unsubscribe from old channels
        channelsRef.current.forEach((channel) =>
          supabase.removeChannel(channel)
        );

        // Set up new subscriptions
        const newChannels = fetchedThreads.map(subscribeToThread);
        channelsRef.current = newChannels;
      } catch (error) {
        console.error("Error setting up subscriptions:", error);
        // Optionally, update an error state here
      }
    };

    setupSubscriptions();

    return () => {
      channelsRef.current.forEach((channel) => supabase.removeChannel(channel));
    };
  }, []); // Empty dependency array to run only on mount and unmount

  const handleAddGroupMember = async () => {
    if (!selectedUserToAdd || !currentThreadId) return;

    const { error } = await supabase.from("GroupMembers").insert({
      user_id: selectedUserToAdd.id,
      group_id: currentThreadId,
    });

    if (error) {
      console.error("Error adding group member:", error);
    } else {
      // Create a new participant object
      const newParticipant = {
        user_id: selectedUserToAdd.id,
        first_name: selectedUserToAdd.first_name,
        last_name: selectedUserToAdd.last_name,
        profile_image_url:
          selectedUserToAdd.profile_image_url ||
          selectedUserToAdd.profile_picture,
      };

      // Update the participants in the threads state
      setThreads((prevThreads) =>
        prevThreads.map((thread) =>
          thread.groupId === currentThreadId
            ? {
                ...thread,
                participants: [...thread.participants, newParticipant],
              }
            : thread
        )
      );

      // Update the currentThread state
      setCurrentThread((prevThread) => ({
        ...prevThread,
        participants: [...prevThread.participants, newParticipant],
      }));

      setSelectedUserToAdd(null);
    }
  };

  const handleRemoveGroupMember = async (userId) => {
    if (!currentThreadId) return;

    const { error } = await supabase
      .from("GroupMembers")
      .delete()
      .eq("group_id", currentThreadId)
      .eq("user_id", userId);

    if (error) {
      console.error("Error removing group member:", error);
    } else {
      // Update the participants in the threads state
      setThreads((prevThreads) =>
        prevThreads.map((thread) =>
          thread.groupId === currentThreadId
            ? {
                ...thread,
                participants: thread.participants.filter(
                  (member) => member.user_id !== userId
                ),
              }
            : thread
        )
      );

      // Update the currentThread state
      setCurrentThread((prevThread) => ({
        ...prevThread,
        participants: prevThread.participants.filter(
          (member) => member.user_id !== userId
        ),
      }));

      setIsRemoveMemberDialogOpen(false); // Assuming you use this state variable
    }
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {error && <Typography color="error">{error}</Typography>}
      <Box
        sx={{
          padding: isMobile ? "0rem 1.5rem 0rem 1.5rem" : "0rem 2rem 0rem 2rem",
        }}
      >
        <Grid container spacing={2} sx={{ height: { xs: "100%", md: "auto" } }}>
          {(!isMobile || mobileView === "threads") && (
            <Grid
              item
              xs={12}
              md={5}
              sx={{ height: { xs: "100%", md: "auto" } }}
            >
              <Container
                sx={{
                  padding: { xs: "0px 0px", md: "0px 0px 20px 0px" },
                  marginTop: isMobile ? "0px" : "30px",
                  boxShadow: !isMobile && "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                  height: { xs: "100%", md: "540px" },
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    textAlign: "left",
                    fontWeight: "500",
                    marginBottom: "20px",
                    paddingTop: "30px",
                    marginLeft: "30px",
                  }}
                >
                  {t("messages")}
                </Typography>

                {threads.map((thread) => (
                  <MessageContainer
                    key={thread.groupId}
                    thread={thread}
                    currentUserId={currentUser.sub}
                    onClick={() => handleChangeThread(thread.groupId)}
                    currentThreadId={currentThreadId}
                    isMobile={isMobile}
                  />
                ))}
              </Container>
            </Grid>
          )}

          {(!isMobile || mobileView === "chat") && (
            <Grid
              item
              xs={12}
              md={7}
              sx={{ paddingTop: isMobile && "0px !important" }}
            >
              <Container
                sx={{
                  padding: { xs: "10px 0px", md: "30px" },
                  marginTop: isMobile ? "5px" : "30px",
                  boxShadow: !isMobile && "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                  height: { xs: "100%", md: "540px" },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* Chat Header */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  {isMobile && (
                    <IconButton
                      aria-label="back"
                      onClick={() => {
                        setMobileView("threads");
                        setCurrentThreadId(null);
                      }}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  )}
                  <Typography variant="h6">{t("chat")}</Typography>
                  <IconButton
                    aria-label="manage"
                    onClick={() => setOpenGroupDialog(true)}
                  >
                    <GroupAddIcon />
                  </IconButton>
                </Box>

                {/* Group Management Dialog */}
                <Dialog
                  open={openGroupDialog}
                  onClose={() => setOpenGroupDialog(false)}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle>{t("manageGroupMembers")}</DialogTitle>
                  <DialogContent>
                    {/* Current Group Members */}
                    <Typography variant="subtitle1">
                      {t("currentMembers")}
                    </Typography>
                    <List>
                      {currentThread &&
                        currentThread.participants &&
                        currentThread.participants.length > 0 &&
                        currentThread.participants.map((member) => (
                          <ListItem key={member.user_id}>
                            <Avatar
                              src={member.profile_image_url}
                              alt={member.first_name}
                            />
                            <ListItemText
                              primary={member.first_name}
                              sx={{ marginLeft: 2 }}
                            />
                            {/* Adjust permissions as needed */}
                            {member.user_id !== currentUser.sub && (
                              <IconButton
                                edge="end"
                                size="small"
                                aria-label="remove"
                                onClick={() => {
                                  setMemberIdToRemove(member.user_id);
                                  setMemberNameToRemove(`${member.first_name}`);
                                  setIsRemoveMemberDialogOpen(true);
                                }}
                              >
                                <RemoveCircleIcon fontSize="inherit" />
                              </IconButton>
                            )}
                          </ListItem>
                        ))}
                    </List>
                    <Dialog
                      open={isRemoveMemberDialogOpen}
                      onClose={() => setIsRemoveMemberDialogOpen(false)}
                      aria-labelledby="confirm-dialog-title"
                      aria-describedby="confirm-dialog-description"
                    >
                      <DialogTitle id="confirm-dialog-title">
                        {"Remove Member"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="confirm-dialog-description">
                          Are you sure you want to remove {memberNameToRemove}{" "}
                          from the group chat?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setIsRemoveMemberDialogOpen(false)}
                          color="primary"
                        >
                          {t("cancel")}
                        </Button>
                        <Button
                          onClick={() => {
                            handleRemoveGroupMember(memberIdToRemove);
                            setIsRemoveMemberDialogOpen(false);
                          }}
                          color="primary"
                          autoFocus
                        >
                          {t("confirm")}
                        </Button>
                      </DialogActions>
                    </Dialog>

                    {/* Add New Member */}
                    <>
                      <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                        {t("addMember")}
                      </Typography>
                      <Autocomplete
                        options={
                          currentThread?.participants
                            ? allUsers.filter(
                                (user) =>
                                  !currentThread.participants.some(
                                    (member) => member.user_id === user.id
                                  )
                              )
                            : allUsers
                        }
                        getOptionLabel={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" />
                        )}
                        value={selectedUserToAdd}
                        onChange={(event, newValue) =>
                          setSelectedUserToAdd(newValue)
                        }
                      />

                      <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={handleAddGroupMember}
                        sx={{
                          marginTop: 2,
                          backgroundColor: "#9f7fe3",
                          ":hover": {
                            bgcolor: "#9f7fe3d8",
                          },
                        }}
                        disabled={!selectedUserToAdd}
                      >
                        {t("add")}
                      </Button>
                    </>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenGroupDialog(false)}>
                      {t("close")}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Message List */}

                <Box
                  sx={{
                    overflowY: "scroll",
                    height: isMobile ? "32rem" : "none",
                    flexGrow: 1,
                    paddingRight: "1rem",
                    WebkitOverflowScrolling: "touch",
                  }}
                >
                  {loading && <CircularProgress color="secondary" />}
                  {messages.map((message) => {
                    const isSender = message.sender_id === currentUser.sub;
                    const sender = message.Users;

                    return (
                      <Box
                        key={message.id}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: isSender ? "flex-end" : "flex-start",
                          marginBottom: "10px",
                          paddingBottom: "20px",
                          paddingRight: !isMobile && "1rem",
                        }}
                      >
                        {/* For messages from other users */}
                        {!isSender && (
                          <div style={{ display: "flex" }}>
                            {/* Sender's Profile Picture at the Bottom Left */}
                            <Avatar
                              src={sender?.profile_picture}
                              alt={sender?.first_name}
                              sx={{
                                width: 24,
                                height: 24,
                                marginRight: "5px",
                                alignSelf: "flex-end",
                                fontSize: "12px",
                                ":hover": {
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() => {
                                navigate(`/profile/${sender.id}`);
                              }}
                            />
                            {/* Sender's Name at the Top */}

                            {/* Message Bubble and Profile Picture */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                sx={{
                                  marginBottom: "5px",
                                  fontSize: "10px",
                                  color: "#8f8f8f",
                                }}
                              >
                                {sender?.first_name}
                              </Typography>

                              {/* Message Bubble */}
                              <Box
                                sx={{
                                  backgroundColor: "#9f7fe314",
                                  borderRadius: "10px",
                                  padding: "10px",
                                  wordWrap: "break-word",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {message.content}
                                </Typography>
                              </Box>
                            </Box>
                          </div>
                        )}

                        {/* For messages from the current user */}
                        {isSender && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row-reverse",
                              alignItems: "flex-end",
                            }}
                          >
                            {/* Message Bubble */}
                            <Box
                              sx={{
                                backgroundColor: "#9f7fe3",
                                borderRadius: "10px",
                                padding: "10px",
                                wordWrap: "break-word",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "12px",
                                  color: "#ffffff",
                                }}
                              >
                                {message.content}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {/* Timestamp */}
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#707070",
                            fontSize: "10px",
                            fontWeight: "300",
                            marginTop: "10px",
                            alignSelf: isSender ? "flex-end" : "flex-start",
                          }}
                        >
                          {dayjs(message.created_at).format("M/D, h:mm A")}
                        </Typography>
                      </Box>
                    );
                  })}
                  <div ref={messagesEndRef} />
                </Box>

                {/* Message Input */}
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "25px",
                    width: "100%",
                    boxShadow: "none !important",
                    backgroundColor: "#9f7fe314",
                    marginTop: "auto",
                    paddingBottom: "env(safe-area-inset-bottom)",
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <AttachFileIcon />
                  </IconButton>
                  <InputBase
                    sx={{
                      ml: 1,
                      flex: 1,
                      fontSize: isMobile ? "16px" : "12px",
                    }}
                    placeholder="Type your message here ..."
                    inputProps={{ "aria-label": "write message" }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        sendMessage();
                      }
                    }}
                  />
                  <IconButton
                    onClick={sendMessage}
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="send"
                  >
                    <SendIcon />
                  </IconButton>
                </Paper>
              </Container>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Chat;
