import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "yet-another-react-lightbox/styles.css";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Divider from "@mui/material/Divider";
import AppsIcon from "@mui/icons-material/Apps";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import TodayIcon from "@mui/icons-material/Today";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IosShareIcon from "@mui/icons-material/IosShare";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Map from "./Map";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { v4 as uuidv4 } from "uuid";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ReactPlayer from "react-player";
import { supabase } from "./SupabaseClient";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  const nameParts = name.split(" ");
  const initials =
    nameParts.length > 1
      ? `${nameParts[0][0]}${nameParts[1][0]}`
      : `${nameParts[0][0]}`;

  return {
    sx: {
      bgcolor: stringToColor(name),
      cursor: "pointer",
    },
    children: initials.toUpperCase(),
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Proposal = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [project, setProject] = useState("");
  const { projectId } = useParams();

  const [images, setImages] = useState([]);
  const [lightBox, setLightBox] = useState([]);
  const [customNote, setCustomNote] = useState("");
  const [openProposalDialog, setOpenProposalDialog] = useState(false);
  const [proposalSubmitted, setProposalSubmitted] = useState(false);
  const location = useLocation();
  const data = location.state;

  const [openAppForm, setOpenAppForm] = useState(false);

  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const { t } = useTranslation();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleAppFormOpen = () => {
    setOpenAppForm(true);
  };

  const handleAppFormClose = () => {
    setOpenAppForm(false);
  };

  const transformArrayImageList = (array) => {
    return array.map((item, index) => ({
      img: item,
    }));
  };

  const handleProposalDialog = () => {
    setOpenProposalDialog(true);
  };

  const transformArrayLightBox = (array) => {
    return array.map((item, index) => {
      // Check if the item is a playable video
      if (ReactPlayer.canPlay(item)) {
        return {
          type: "custom-slide",
          url: item,
        };
      } else {
        // Handle non-video items
        return {
          src: item,
        };
      }
    });
  };

  const getProject = async () => {
    const { data, error } = await supabase
      .from("Projects")
      .select(
        `
          *,
          Users (first_name, last_name, profile_picture),
          ProjectRoles (
            *,
            Users (id,first_name, last_name, profile_picture)
          )
        `
      )
      .eq("id", projectId);

    if (data) {
      const project = data[0];

      setProject(project);
      setImages(transformArrayImageList(project.media));
      setLightBox(transformArrayLightBox(project.media));

      setProject(project);
    }

    if (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    getProject();
    handleProposalCheck();
  }, [projectId]);

  const handleProposalSubmit = () => {
    submitProposal();
    setOpenProposalDialog(false);
  };

  const submitProposal = async () => {
    const { data, error } = await supabase.from("Proposals").insert([
      {
        project_id: projectId,
        note: customNote,
        status: "pending",
      },
    ]);

    if (error) {
      console.error("Error submitting proposal:", error);
      return;
    }

    if (data) {
      setProposalSubmitted(true);
      setOpenProposalDialog(false);
    }
  };

  const handleImageClick = (index) => {
    setIndex(index);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProposalCheck = async () => {
    const { data: existingProposal, error: proposalError } = await supabase
      .from("Proposals")
      .select()
      .eq("project_id", projectId)
      .eq("user", currentUser.sub)
      .single();

    if (proposalError) {
      console.error("Error checking existing proposal:", proposalError);
      return;
    }

    if (existingProposal) {
      setProposalSubmitted(true);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {project ? (
        <React.Fragment>
          <Carousel
            sx={{ display: { xs: "flex", md: "none" } }}
            responsive={responsive}
            partialVisibile={false}
            swipeable={false}
            containerClass="carousel-container"
          >
            {images &&
              images.length > 0 &&
              images.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleImageClick(index)}
                  className="carousel-item"
                >
                  {ReactPlayer.canPlay(item.img) ? (
                    <ReactPlayer
                      width={380}
                      height={250}
                      url={item.img}
                    ></ReactPlayer>
                  ) : (
                    <img
                      src={`${item.img}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.img}
                      loading="lazy"
                    />
                  )}
                </div>
              ))}
          </Carousel>
          <Box
            className="custom-margins project-view"
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "3rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                marginBottom: "24px",
                alignItems: "flex-start",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontWeight: "400",
                    fontSize: "26px",
                  }}
                >
                  {project.name}
                </Typography>
              </Box>
            </Box>
            <Container
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "left",
                padding: "0 !important",
                gap: { xs: "40px", md: "40px" },
                margin: "0px !important",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "row" },
                  gap: { xs: "10px", md: "20px" },
                  width: { xs: "100%", md: "auto" },
                  flexWrap: { xs: "wrap", md: "nowrap" },
                  alignItems: "center",
                }}
              >
                <Avatar
                  className="team-avatar"
                  {...stringAvatar(
                    `${
                      project.Users.first_name + " " + project.Users.last_name
                    }`
                  )} // Use project.user.name for the avatar
                  src={project.Users.profile_picture}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                  }}
                >
                  <div>
                    {`${project.Users.first_name} ${project.Users.last_name}`}
                  </div>
                </div>
              </Box>
              {proposalSubmitted ? (
                <button
                  className="submit-proposal-btn"
                  style={{ minWidth: "100px", fontSize: "12px" }}
                  disabled
                >
                  {t("submitted")}
                </button>
              ) : (
                <button
                  className="submit-proposal-btn"
                  style={{ minWidth: "100px", fontSize: "12px" }}
                  onClick={handleProposalDialog}
                >
                  {t("submitProposal")}
                </button>
              )}
            </Container>
            <Divider sx={{ marginTop: "30px", marginBottom: "30px" }} />
            <Grid
              sx={{ display: { xs: "none", md: "flex" } }}
              container
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    textAlign: "left",
                    fontWeight: "500",
                    textTransform: "none",
                  }}
                >
                  {t("moodinspo")}
                </Typography>
              </Grid>
              {project.media &&
                project.media.slice(0, 8).map((media, index) => (
                  <Grid
                    key={index}
                    sx={{
                      width: "272px",
                      height: "172px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    item
                    xs={3}
                    onClick={handleClickOpen}
                  >
                    {ReactPlayer.canPlay(media) ? (
                      <ReactPlayer width={294} height={164} url={media} />
                    ) : (
                      <img
                        className="child-img"
                        src={media}
                        alt={`media-${index}`}
                      />
                    )}
                  </Grid>
                ))}
              {project.media && project.media.length > 8 && (
                <Button
                  onClick={handleClickOpen}
                  className="img-btn"
                  variant="outlined"
                  startIcon={<AppsIcon />}
                  sx={{ textTransform: "none" }}
                >
                  Show all photos
                </Button>
              )}
              <Grid item xs={12}>
                <Divider sx={{ marginTop: "30px", marginBottom: "30px" }} />
              </Grid>
            </Grid>
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  padding: "20px 0px 0px 20px !important",
                  marginBottom: "30px",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <IconButton
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <NavigateBeforeIcon />
                </IconButton>
              </Container>
              <Container sx={{ paddingBottom: "40px" }}>
                <ImageList variant="masonry" cols={3} gap={4}>
                  {images &&
                    images.length > 0 &&
                    images.map((item, index) => (
                      <ImageListItem
                        key={index}
                        onClick={() => handleImageClick(index)}
                        sx={{ cursor: "pointer" }}
                      >
                        {ReactPlayer.canPlay(item.img) ? (
                          <ReactPlayer
                            width={380}
                            height={250}
                            url={item.img}
                          ></ReactPlayer>
                        ) : (
                          <img
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.img}
                            loading="lazy"
                          />
                        )}
                      </ImageListItem>
                    ))}
                </ImageList>
                {lightBox && lightBox.length > 0 && (
                  <Lightbox
                    slides={lightBox}
                    plugins={[Video]}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    render={{
                      slide: ({ slide }) =>
                        slide.type === "custom-slide" ? (
                          <ReactPlayer
                            width={1280}
                            height={650}
                            url={slide.url}
                          ></ReactPlayer>
                        ) : undefined,
                    }}
                    // enable optional lightbox plugins
                  />
                )}
              </Container>
            </Dialog>
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  textAlign: "left",
                  fontWeight: "500",
                  textTransform: "none",
                }}
              >
                {t("aboutTheProject")}
              </Typography>
              {project.date && (
                <Box
                  sx={{
                    textAlign: "left",
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <TodayIcon sx={{ marginRight: "10px" }} />
                  <Typography
                    variant="button"
                    sx={{
                      textAlign: "left",
                      fontWeight: "400",
                      textTransform: "none",
                    }}
                  >
                    {t("projectDate")}: {dayjs(project.date).format("M/D/YY")}
                  </Typography>
                </Box>
              )}
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  textAlign: "left",
                  textTransform: "none",
                }}
                dangerouslySetInnerHTML={{
                  __html: project.description,
                }}
              ></Typography>

              {project && project.coordinates && (
                <React.Fragment>
                  <Divider
                    sx={{
                      marginTop: "30px",
                      marginBottom: "30px",
                      display: { xs: "flex", md: "none" },
                    }}
                  />
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      fontWeight: "500",
                      textTransform: "none",
                      marginBottom: "20px",
                    }}
                  >
                    {t("location")}
                  </Typography>
                  <Box sx={{ width: "auto", height: "400px" }}>
                    {project && project.coordinates && (
                      <Map center={project.coordinates} zoom={14} />
                    )}
                  </Box>
                </React.Fragment>
              )}
            </Box>

            <Dialog
              open={openProposalDialog}
              onClose={handleProposalDialog}
              PaperProps={{
                sx: {
                  padding: "30px", // Add padding to the entire dialog
                },
              }}
            >
              <DialogTitle sx={{ padding: "0 0 20px 0" }}>Proposal</DialogTitle>
              <DialogContent sx={{ padding: "0" }}>
                <DialogContentText>
                  Please add a custom note for the project owner. Your profile
                  will be shared once you apply.
                </DialogContentText>
                <TextField
                  multiline
                  rows={10}
                  fullWidth
                  sx={{ marginTop: "20px" }}
                  value={customNote}
                  onChange={(e) => setCustomNote(e.target.value)}
                />
              </DialogContent>
              <DialogActions sx={{ padding: "20px 0 0 0" }}>
                <Button
                  onClick={() => {
                    setOpenProposalDialog(false);
                  }}
                  sx={{ fontSize: "10px !important" }}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ fontSize: "10px !important" }}
                  onClick={handleProposalSubmit}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </React.Fragment>
      ) : (
        <Stack
          className="custom-margins"
          spacing={4}
          sx={{
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
        >
          <Skeleton
            variant="rounded"
            height={300}
            sx={{
              display: {
                xs: "flex",
                md: "none",
              },
            }}
          />
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="rounded" width={300} height={70} />
          <Skeleton variant="rounded" width={600} height={40} />
          <Grid
            sx={{
              marginBottom: "40px",
              display: {
                xs: "none",
                md: "flex",
              },
            }}
            container
            spacing={1}
          >
            <Grid
              sx={{
                width: "560px",
                height: "344px",
                position: "relative",
                overflow: "hidden",
                paddingLeft: "0px !important",
              }}
              item
              xs={6}
            >
              <Skeleton variant="rounded" height={320} />
            </Grid>

            <Grid
              item
              xs={6}
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>

                <Grid
                  sx={{
                    width: "272px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>

                <Grid
                  sx={{
                    width: "270px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>

                <Grid
                  sx={{
                    width: "270px",
                    height: "172px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  item
                  xs={6}
                >
                  <Skeleton variant="rounded" height={150} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Skeleton variant="rounded" width={1200} height={300} />
        </Stack>
      )}
    </Box>
  );
};

export default Proposal;
