import React from "react";
import { useState, useEffect } from "react";
import { supabase } from "./SupabaseClient";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const AuthComponent = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [active, setActive] = useState("login");
  const [errorMessage, setErrorMessage] = useState("");
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [birthdate, setBirthdate] = useState(null);

  const { t } = useTranslation();

  const isPasswordValid = () => {
    const passwordPattern =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordPattern.test(password)) {
      setErrorMessage(t("passError"));
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return false;
    }
    return true;
  };

  const handleLoginPress = (event) => {
    // Check if the pressed key is "Enter"
    if (event.key === "Enter") {
      // Prevent the default action to avoid submitting the form (if any)
      event.preventDefault();
      // Trigger the button click
      if (email && password) {
        handleLogin();
      }
    }
  };

  const handlePasswordResetPress = (event) => {
    // Check if the pressed key is "Enter"
    if (event.key === "Enter") {
      // Prevent the default action to avoid submitting the form (if any)
      event.preventDefault();
      // Trigger the button click
      if (email) {
        handlePasswordReset();
      }
    }
  };

  const handleSignUpPress = (event) => {
    // Check if the pressed key is "Enter"
    if (event.key === "Enter" && !showLoading) {
      // Prevent the default action to avoid submitting the form (if any)
      event.preventDefault();
      // Trigger the button click
      if (
        email &&
        password &&
        confirmPassword &&
        firstName &&
        lastName &&
        birthdate
      ) {
        handleSignUp();
      }
    }
  };

  /**
   * Step 1: Send the user an email to get a password reset token.
   * This email contains a link which sends the user back to your application.
   */
  const handlePasswordReset = async () => {
    if (email === "") {
      setErrorMessage(t("missingEmail"));
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: "https://createtogather.com/update-password",
    });

    if (error) {
    } else {
      setShowEmailConfirmation(true);
      setTimeout(() => {
        setShowEmailConfirmation(false);
      }, 3000);
    }
  };
  /**
   * Step 2: Once the user is redirected back to your application,
   * ask the user to reset their password.
   */
  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event == "PASSWORD_RECOVERY") {
        const newPassword = prompt(t("newPasswordPrompt"));
        const { data, error } = await supabase.auth.updateUser({
          password: newPassword,
        });

        if (data) alert(t("passwordUpdated"));
        if (error) alert(t("passwordUpdateError"));
      }
    });
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSignUp = async () => {
    if (
      email === "" ||
      confirmPassword === "" ||
      password === "" ||
      firstName === "" ||
      lastName === "" ||
      birthdate === null
    ) {
      setErrorMessage(t("allFieldsRequired"));
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }
    if (password !== confirmPassword) {
      setErrorMessage(t("passMatchError"));
      setShowLoading(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }

    if (!isPasswordValid()) {
      return;
    }
    setShowLoading(true);

    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          first_name: capitalizeFirstLetter(firstName),
          last_name: capitalizeFirstLetter(lastName),
          birthdate: dayjs(birthdate).format("M/D/YY"),
        },
      },
    });

    if (error) {
      setErrorMessage(t("signupError"));
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    } else {
      setActive("confirm_email");
      setShowLoading(false);
    }
  };

  const handleLogin = async () => {
    if (email === "" || password === "") {
      setErrorMessage(t("missingEmailOrPass"));
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }

    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      setErrorMessage(t("incorrectLogin"));
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    } else {
      localStorage.setItem("user", JSON.stringify(data.user.user_metadata));
      navigate("/");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        justifyItems: "center",
        height: "100dvh",
      }}
    >
      <div className="auth-container">
        <img
          alt="logo"
          src="/images/togather_logo.svg"
          style={{
            width: 60,
            marginBottom: "30px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        ></img>
        {active === "login" && (
          <React.Fragment>
            <div className="auth-label-wrapper">
              <div className="auth-label">{t("email")}</div>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="auth-label-wrapper">
              <div className="auth-label">{t("password")}</div>
              <TextField
                onKeyDown={handleLoginPress}
                fullWidth
                type="password"
                size="small"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button
              onClick={handleLogin}
              className="auth-login-btn"
              variant="contained"
            >
              {t("login")}
            </Button>
            {errorMessage && (
              <div className="auth-error-msg">{errorMessage}</div>
            )}
            <div
              onClick={() => setActive("forgot_pass")}
              className="auth-bottom-nav"
            >
              {t("forgotPassword")}
            </div>
            <div
              onClick={() => setActive("signup")}
              className="auth-bottom-nav"
            >
              {t("createAnAccount")}
            </div>
          </React.Fragment>
        )}
        {active === "signup" && (
          <React.Fragment>
            <div className="auth-signup-row">
              <div className="auth-label-wrapper">
                <div className="auth-label">{t("firstName")}</div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="auth-label-wrapper">
                <div className="auth-label">{t("lastName")}</div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="auth-signup-row">
              <div className="auth-label-wrapper">
                <div className="auth-label">{t("email")}</div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="auth-label-wrapper">
                <div className="auth-label">{t("birthdate")}</div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    required
                    value={birthdate}
                    onChange={(newValue) => {
                      setBirthdate(newValue);
                    }}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="auth-signup-row">
              <div className="auth-label-wrapper">
                <div className="auth-label">{t("password")}</div>
                <TextField
                  fullWidth
                  type="password"
                  size="small"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="auth-label-wrapper">
                <div className="auth-label">{t("confirmPassword")}</div>
                <TextField
                  onKeyDown={handleSignUpPress}
                  type="password"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>

            <Button
              disabled={showLoading}
              onClick={handleSignUp}
              className="auth-login-btn"
              variant="contained"
            >
              {t("createAccount")}
            </Button>
            {showLoading && <CircularProgress sx={{ color: "grey" }} />}
            {errorMessage && (
              <div className="auth-error-msg">{errorMessage}</div>
            )}
            <div onClick={() => setActive("login")} className="auth-bottom-nav">
              {t("alreadyHaveAccount")}
            </div>
            <div
              onClick={() => setActive("forgot_pass")}
              className="auth-bottom-nav"
            >
              {t("forgotPassword")}
            </div>
          </React.Fragment>
        )}
        {active === "confirm_email" && (
          <React.Fragment>
            <CheckCircleOutlineIcon
              sx={{ fontSize: "60px", color: "#6fce6f", marginTop: "10px" }}
            />
            <div className="auth-label">{t("confirmEmail")}</div>
            <div className="auth-label-spam">{t("checkSpam")}</div>
          </React.Fragment>
        )}
        {active === "forgot_pass" && (
          <React.Fragment>
            <div className="auth-label-wrapper">
              <div className="auth-label">{t("email")}</div>
              <TextField
                onKeyDown={handlePasswordResetPress}
                fullWidth
                size="small"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <Button
              onClick={handlePasswordReset}
              className="auth-login-btn"
              variant="contained"
            >
              {t("resetPassword")}
            </Button>
            {errorMessage && (
              <div className="auth-error-msg">{errorMessage}</div>
            )}
            {showEmailConfirmation && (
              <div className="auth-success-msg">{t("checkEmail")}</div>
            )}
            <div onClick={() => setActive("login")} className="auth-bottom-nav">
              {t("alreadyHaveAccount")}
            </div>
            <div
              onClick={() => setActive("signup")}
              className="auth-bottom-nav"
            >
              {t("createAnAccount")}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default AuthComponent;
