// Footer.js

import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Facebook,
  LinkedIn,
  Twitter,
  YouTube,
  Instagram,
} from "@mui/icons-material";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";

const Footer = () => {
  const isMobile = window.innerWidth <= 600;

  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        px: 2,
        mt: "2rem",
        backgroundColor: "#1f1f1f",
        color: "white",
        paddingLeft: "4rem",
        paddingRight: "4rem",
        paddingTop: "4rem",
      }}
    >
      <Grid container spacing={3} justifyContent="center">
        {isMobile ? (
          // Mobile View with Accordions in Vertical Stack
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Accordion
              sx={{
                backgroundColor: "#1f1f1f",
                color: "white",
                boxShadow: "none",
                "&:before": { display: "none" }, // Remove the default divider line
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              >
                <Typography variant="body1" fontWeight="bold">
                  About Us
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  gap: "2px",
                  flexDirection: "column",
                }}
              >
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  About Us
                </Link>
                <br />
                <Link
                  to="/feedback"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Feedback
                </Link>
                <br />
                <Link
                  to="/community"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Community
                </Link>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: "#1f1f1f",
                color: "white",
                boxShadow: "none",
                "&:before": { display: "none" },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              >
                <Typography variant="body1" fontWeight="bold">
                  Support
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  gap: "2px",
                  flexDirection: "column",
                }}
              >
                <Link
                  to="/safety"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Trust, Safety & Security
                </Link>
                <br />
                <Link
                  to="/help"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Help & Support
                </Link>
                <br />
                <Link
                  to="/foundation"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Foundation
                </Link>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: "#1f1f1f",
                color: "white",
                boxShadow: "none",
                "&:before": { display: "none" },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              >
                <Typography variant="body1" fontWeight="bold">
                  Terms
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  gap: "2px",
                  flexDirection: "column",
                }}
              >
                <Link
                  to="/tos"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Terms of Service
                </Link>
                <br />
                <Link
                  to="/privacy"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Privacy Policy
                </Link>
                <br />
                <Link
                  to="/cookies"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Cookie Settings
                </Link>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: "#1f1f1f",
                color: "white",
                boxShadow: "none",
                "&:before": { display: "none" },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              >
                <Typography variant="body1" fontWeight="bold">
                  More
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  gap: "2px",
                  flexDirection: "column",
                }}
              >
                <Link
                  to="/accessibility"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Accessibility
                </Link>
                <br />
                <Link
                  to="/desktop"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Desktop App
                </Link>
                <br />
                <Link
                  to="/enterprise"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Enterprise Solutions
                </Link>
              </AccordionDetails>
            </Accordion>

            <Box textAlign="center" mt={3}>
              <Typography variant="body1" fontWeight="bold" mb={1}>
                Follow Us
              </Typography>
              <Box display="flex" justifyContent="center">
                <IconButton href="https://facebook.com" color="inherit">
                  <Facebook />
                </IconButton>
                <IconButton href="https://linkedin.com" color="inherit">
                  <LinkedIn />
                </IconButton>
                <IconButton href="https://twitter.com" color="inherit">
                  <Twitter />
                </IconButton>
                <IconButton href="https://youtube.com" color="inherit">
                  <YouTube />
                </IconButton>
                <IconButton href="https://instagram.com" color="inherit">
                  <Instagram />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ) : (
          // Desktop View with Grid
          <>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                General
              </Typography>
              <Link
                to="/about"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                About Us
              </Link>
              <Link
                to="/feedback"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Feedback
              </Link>
            </Grid>

            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                Support
              </Typography>
              <Link
                to="/safety"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Trust, Safety & Security
              </Link>
              <Link
                to="/help"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Help & Support
              </Link>
            </Grid>

            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                Terms
              </Typography>
              <Link
                to="/terms"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Terms of Service
              </Link>
              <Link
                to="/privacy"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Privacy Policy
              </Link>
              <Link
                to="/cookies"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Cookie Settings
              </Link>
            </Grid>

            {/* <Grid
              item
              xs={12}
              sm={2}
              sx={{
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                More
              </Typography>
              <Link
                to="/accessibility"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Accessibility
              </Link>
            </Grid> */}

            <Grid item xs={12} sm={4} container justifyContent="center">
              <Typography variant="body1" fontWeight="bold" mb={1}>
                Follow Us
              </Typography>
              <Box display="flex" justifyContent="center">
                {/* <IconButton href="https://facebook.com" color="inherit">
                  <Facebook />
                </IconButton>
                <IconButton href="https://linkedin.com" color="inherit">
                  <LinkedIn />
                </IconButton>
                <IconButton href="https://twitter.com" color="inherit">
                  <Twitter />
                </IconButton>
                <IconButton href="https://youtube.com" color="inherit">
                  <YouTube />
                </IconButton> */}
                <IconButton
                  href="https://www.instagram.com/togatherhere/#"
                  color="inherit"
                >
                  <Instagram />
                </IconButton>
              </Box>
            </Grid>
          </>
        )}
      </Grid>

      <Box mt={10} textAlign="center">
        <Typography variant="body2" color="inherit">
          © {new Date().getFullYear()} Togather - All Rights Reserved
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
