// UserContext.js
import React, { createContext, useState, useEffect } from "react";
import { supabase } from "./SupabaseClient"; // Adjust the import path accordingly

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user"))); // User data including profile_picture
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) return;
    // Fetch user data when the component mounts
    const fetchUser = async () => {
      const { data, error } = await supabase
        .from("Users")
        .select("*")
        .eq("id", user.sub)
        .single();

      if (error) {
        console.error("Error fetching user data:", error);
      } else {
        setUser(data);
      }
      setLoading(false);
    };

    fetchUser();
  }, []);

  const updateUser = (updatedData) => {
    setUser((prevUser) => ({ ...prevUser, ...updatedData }));
  };

  return (
    <UserContext.Provider value={{ user, updateUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};
