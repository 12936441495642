import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Chip,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Skeleton,
  Avatar,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactPlayer from "react-player";
import ProjectRoles from "./ProjectRoles";
import { supabase } from "./SupabaseClient";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7,
    partialVisibilityGutter: 10,
  },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 2 },
};

function stringAvatar(name) {
  const [firstInitial, secondInitial] = name.split(" ").map((n) => n[0]);
  return {
    sx: { bgcolor: "#fff", color: "#000" },
    children: `${firstInitial} ${secondInitial || ""}`.trim(),
  };
}

const ProjectCard = ({
  project,
  navigateToProjectPage,
  navigateToProposalPage,
  colors,
  getNextColorIndex,
  stringAvatar,
  calculateDuration,
}) => {
  const { t } = useTranslation();

  const renderMedia = () => {
    if (project.media.length > 0) {
      if (ReactPlayer.canPlay(project.media[0])) {
        return (
          <CardMedia
            onClick={() =>
              project.proposal_stage === "complete"
                ? navigateToProjectPage(project.id)
                : navigateToProposalPage(project.id)
            }
            className="card-media"
          >
            <div style={{ position: "relative", height: "238px" }}>
              <ReactPlayer
                url={project.media[0]}
                width="300px"
                height="288px"
                playing
                loop
                volume={1}
                muted
                controls={false}
                className="react-player"
              />
              <div
                className="gradient-overlay"
                style={{
                  position: "absolute",
                  height: "288px",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%)",
                }}
              />
              <div style={{ position: "relative", bottom: "288px" }}>
                <div className="title">{project.name}</div>
                {project.ProjectRoles && (
                  <ProjectRoles roles={project.ProjectRoles} />
                )}
              </div>
            </div>
            {renderOwnerInfo()}
          </CardMedia>
        );
      } else {
        return (
          <CardMedia
            onClick={() =>
              project.proposal_stage === "complete"
                ? navigateToProjectPage(project.id)
                : navigateToProposalPage(project.id)
            }
            className="card-media"
            sx={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url(${project.media[0]}) lightgray 50% / cover no-repeat`,
            }}
            title={t("photoshoot")}
          >
            {renderProjectInfo()}
            {renderOwnerInfo()}
          </CardMedia>
        );
      }
    } else {
      return (
        <CardMedia
          onClick={() => navigateToProjectPage(project.id)}
          className="card-media"
          sx={{
            background: `${colors[getNextColorIndex()]}`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          title={t("photoshoot")}
        >
          {renderProjectInfo()}
          {renderOwnerInfo()}
        </CardMedia>
      );
    }
  };

  const renderProjectInfo = () => (
    <div>
      <div className="title">{project.name}</div>

      {project.ProjectRoles && project.proposal_stage !== "pending" && (
        <ProjectRoles roles={project.ProjectRoles} />
      )}
    </div>
  );

  const renderOwnerInfo = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "10px",
        zIndex: 1,
      }}
    >
      <Avatar
        className="avatar"
        {...stringAvatar(
          `${project.Users.first_name} ${project.Users.last_name}`
        )}
        src={project.Users.profile_picture}
      />
      <div className="owner">{`${project.Users.first_name} ${project.Users.last_name}`}</div>
    </div>
  );

  return (
    <Card key={project.id} className="card-project">
      {renderMedia()}
      <CardContent sx={{ padding: "12px 12px 12px 12px", height: "50px" }}>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: project.description }}
        />
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "space-around" }}>
        {project.proposal_stage !== "pending" && (
          <Chip
            className="chip"
            icon={<GroupsIcon sx={{ color: "#929292", width: "19px" }} />}
            label={
              project.ProjectRoles && `${project.ProjectRoles.length} people`
            }
          />
        )}
        {project.duration && project.duration_type && (
          <Chip
            className="chip"
            icon={<TimelapseIcon sx={{ color: "#929292", width: "19px" }} />}
            label={calculateDuration(project.duration, project.duration_type)}
          />
        )}
        {project.proposal_stage !== "pending" ? (
          <Button
            size="small"
            variant="outlined"
            className="project-card-btn"
            onClick={() => navigateToProjectPage(project.id)}
          >
            {t("learnMore")}
          </Button>
        ) : (
          <Button
            size="small"
            variant="outlined"
            className="project-card-btn"
            sx={{
              width: "auto",
            }}
            onClick={() => navigateToProposalPage(project.id)}
          >
            {t("submitProposal")}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState("collaborate");
  const colors = ["#333333", "#F28EAC", "#79B4FA", "#9F7FE3"];
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState("paid");

  let lastColorIndex = -1;

  const getNextColorIndex = () => {
    let nextIndex;
    do {
      nextIndex = Math.floor(Math.random() * colors.length);
    } while (nextIndex === lastColorIndex);
    lastColorIndex = nextIndex;
    return nextIndex;
  };

  const navigateToProjectPage = (id) => {
    addViews(id);
    navigate(`/project/${id}`);
  };

  const navigateToProposalPage = (id) => {
    navigate(`/proposal/${id}`);
  };

  const navigateToCreateProjectPage = () => {
    navigate("/createproject");
  };

  const addViews = async (id) => {
    const { error } = await supabase.rpc("increment", { row_id: id });
    if (error) {
      console.error(error);
    }
  };

  const getCategories = async () => {
    const { data, error } = await supabase.from("Categories").select("*");
    if (data) {
      setCategories(data);
    }
  };

  const fetchProjects = async (orderBy, isPopular = false) => {
    setLoading(true);
    const { data, error } = await supabase
      .from("Projects")
      .select(
        `
      *,
      Users (first_name, last_name, profile_picture),
      ProjectRoles (*)
    `
      )
      .eq("proposal_stage", "complete")
      .order(orderBy, { ascending: false });

    if (data) {
      setLoading(false);
      setProjects(data);
    }
  };

  const fetchProposals = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("Projects")
      .select(
        `
      *,
      Users (first_name, last_name, profile_picture),
      ProjectRoles (*)
    `
      )
      .eq("type", "paid")
      .eq("proposal_stage", "pending")
      .order("created_at", { ascending: false });

    if (data) {
      setLoading(false);
      setProjects(data);
    }
  };

  const fetchCollabProjects = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("Projects")
      .select(
        `
      *,
      Users (first_name, last_name, profile_picture),
      ProjectRoles (*)
    `
      )
      .eq("type", "collab")
      .eq("proposal_stage", "complete")
      .order("created_at", { ascending: false });

    if (data) {
      setLoading(false);
      setProjects(data);
    }
  };

  const fetchPaidProjects = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("Projects")
      .select(
        `
      *,
      Users (first_name, last_name, profile_picture),
      ProjectRoles (*)
    `
      )
      .eq("type", "paid")
      .eq("proposal_stage", "complete")
      .order("created_at", { ascending: false });

    if (data) {
      setLoading(false);
      setProjects(data);
    }
  };

  const getProjectsByFilter = async (option) => {
    setLoading(true);
    const timer = setTimeout(() => {
      if (option === "proposals") {
        fetchProposals();
      } else if (option === "collab") {
        fetchCollabProjects();
      } else if (option === "paid") {
        fetchPaidProjects();
      }
    }, 1000);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    fetchProjects("created_at");
    getCategories();
  }, []);

  const calculateDuration = (duration, durationType) => {
    const durations = {
      Hours: "Hour",
      Days: "Day",
      Weeks: "Week",
      Months: "Month",
    };
    return duration > 1
      ? `${duration} ${durations[durationType]}s`
      : `${duration} ${durations[durationType]}`;
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", paddingBottom: "3rem" }}
      >
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            paddingRight: "70px",
            marginBottom: "1px",
          }}
        >
          {["collaborate", "paid"].map((tab) => (
            <div
              key={tab}
              className="box"
              style={{
                zIndex: activeTab === tab ? "2" : "1",
                cursor: "pointer",
              }}
              onClick={() => {
                setActiveTab(tab);
                if (tab === "collaborate") {
                  fetchCollabProjects();
                }
                if (tab === "paid") {
                  fetchPaidProjects();
                }
              }}
            >
              <div className="rectangle">
                <div className="text-wrapper">
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </div>
              </div>
              <div
                style={{
                  display: activeTab === tab ? "block" : "none",
                  width: "123px",
                  height: "7px",
                  backgroundColor: "white",
                }}
              />
            </div>
          ))}
        </Box> */}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box component="main" className="categories-container">
            {/* <button
              onClick={navigateToCreateProjectPage}
              variant="outlined"
              className="create-project-btn"
            >
              {t("createProject")}{" "}
              <img
                alt="add icon"
                src="/images/add_icon.svg"
                style={{ height: "14px" }}
              />
            </button>
            <Divider orientation="vertical" variant="middle" flexItem /> */}
            <Box sx={{ display: "flex", gap: 3 }}>
              <Typography
                onClick={() => {
                  setLoading(true);
                  setProjects([]);
                  setActiveCategory("paid");
                  getProjectsByFilter("paid");
                }}
                sx={{
                  cursor: "pointer",
                  borderBottom:
                    activeCategory === "paid" ? "2px solid #9F7FE3" : "none",
                  padding: "4px 12px 4px 12px",
                  fontSize: "14px",
                  fontWeight: activeCategory === "paid" ? "400" : "300",
                }}
              >
                {t("paid")}
              </Typography>
              <Typography
                onClick={() => {
                  setLoading(true);
                  setProjects([]);
                  setActiveCategory("collab");
                  getProjectsByFilter("collab");
                }}
                sx={{
                  cursor: "pointer",
                  borderBottom:
                    activeCategory === "collab" ? "2px solid #9F7FE3" : "none",
                  padding: "4px 12px 4px 12px",
                  fontSize: "14px",
                  fontWeight: activeCategory === "collab" ? "400" : "300",
                }}
              >
                {t("collab")}
              </Typography>
              <Typography
                onClick={() => {
                  setLoading(true);
                  setProjects([]);
                  setActiveCategory("proposals");
                  getProjectsByFilter("proposals");
                }}
                sx={{
                  cursor: "pointer",
                  borderBottom:
                    activeCategory === "proposals"
                      ? "2px solid #9F7FE3"
                      : "none",
                  padding: "4px 12px 4px 12px",
                  fontSize: "14px",
                  fontWeight: activeCategory === "proposals" ? "400" : "300",
                }}
              >
                {t("acceptingProposals")}
              </Typography>
            </Box>
            {/* <Carousel
              responsive={responsive}
              partialVisibile={true}
              swipeable={true}
              draggable={true}
              arrows={false}
              containerClass="category-carousel"
              itemClass="category-item-class"
              removeArrowOnDeviceType="mobile"
            >
              {categories.map((category) => (
                <button
                  key={category.name}
                  className="button-flex"
                  onClick={() => getProjectsByCategory(category.name)}
                >
                  {category.name}
                </button>
              ))}
            </Carousel> */}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginBottom: "30px",
              marginTop: "30px",
            }}
            className="custom-margins"
          >
            {!loading && projects.length === 0 && (
              <Typography sx={{ fontWeight: "300", fontSize: "14px" }}>
                No projects to show
              </Typography>
            )}
            <Box className="cards-container">
              {loading &&
                Array.from({ length: 4 }).map((_, index) => (
                  <Card key={index} className="card-project">
                    <CardMedia
                      sx={{ width: "300px", height: "288px" }}
                      title="photoshoot"
                    >
                      <Skeleton variant="rounded" width="100%" height="100%" />
                    </CardMedia>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "10px 8px 0px 8px",
                        height: "110px",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Skeleton variant="text" width="80%" />
                      <Skeleton variant="text" width="80%" />
                    </Box>
                  </Card>
                ))}
              {projects.map((project) => (
                <ProjectCard
                  key={project.id}
                  project={project}
                  navigateToProjectPage={navigateToProjectPage}
                  colors={colors}
                  getNextColorIndex={getNextColorIndex}
                  stringAvatar={stringAvatar}
                  calculateDuration={calculateDuration}
                  navigateToProposalPage={navigateToProposalPage}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
