import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactPlayer from "react-player";
import { supabase } from "./SupabaseClient";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

function stringAvatar(name) {
  const parts = name.split(" ");
  const firstInitial = parts[0][0];
  const secondInitial = parts.length > 1 ? parts[1][0] : ""; // Check if there is a second part, use it if so, or default to an empty string

  return {
    sx: {
      bgcolor: "#fff",
      color: "#000",
    },
    children: `${firstInitial} ${secondInitial}`.trim(), // Trim to remove any trailing space if there's no second initial
  };
}

const MyProposals = () => {
  const navigate = useNavigate();
  const colors = ["#333333", "#F28EAC", "#79B4FA", "#9F7FE3"];
  const [myProposals, setMyProposals] = useState([]);
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [inProgress, setInProgress] = useState(true);
  const [open, setOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectToDelete, setProjectToDelete] = useState("");
  const { t } = useTranslation();

  let lastColorIndex = -1;

  // Function to get the next color index
  const getNextColorIndex = () => {
    let nextIndex;
    do {
      nextIndex = Math.floor(Math.random() * colors.length);
    } while (nextIndex === lastColorIndex);
    lastColorIndex = nextIndex;
    return nextIndex;
  };

  const navigateToProjectPage = (id) => {
    navigate(`/project/${id}`);
  };

  const navigateToEditProjectPage = (id) => {
    navigate(`/editproject/${id}`);
  };

  const navigateToProposalPage = (id) => {
    navigate(`/proposal/${id}`);
  };

  const handleClose = () => {
    setOpen(false);
    setProjectName("");
    setProjectToDelete("");
  };

  const handleOpen = (projectName, projectId) => {
    setOpen(true);
    setProjectName(projectName);
    setProjectToDelete(projectId);
  };

  const getMyProposals = async () => {
    setInProgress(true);
    const { data, error } = await supabase
      .from("Proposals")
      .select(
        `
  *,
  Projects (
  id,
    name, 
    media, 
    description, 
    duration, 
    duration_type,
    Users (
      first_name,
      last_name,
      profile_picture
    )
  )
`
      )
      .eq("user", currentUser.sub);
    if (data) {
      setInProgress(false);
      setMyProposals(data);
    }
  };

  useEffect(() => {
    getMyProposals();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins"
        sx={{
          paddingBottom: "3rem",
          paddingTop: "2rem",
        }}
      >
        {" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: "30px",
            alignItems: {
              xs: "center",
              sm: "flex-start",
            },
          }}
        >
          <Typography
            sx={{ color: "#222222", textAlign: "left", marginBottom: "30px" }}
            variant="h5"
          >
            {t("myProposals")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
              },
              gap: "25px",
              flexWrap: "wrap",
              alignItems: {
                xs: "center",
                sm: "flex-start",
              },
            }}
          >
            {myProposals &&
              myProposals.length > 0 &&
              myProposals.map((proposal) => {
                return (
                  <Card key={proposal.Projects.id} className="card-project">
                    {proposal.Projects.media.length > 0 &&
                      ReactPlayer.canPlay(proposal.Projects.media[0]) && (
                        <CardMedia
                          onClick={() =>
                            navigateToProposalPage(proposal.Projects.id)
                          }
                          className="card-media"
                        >
                          <div
                            style={{ position: "relative", height: "238px" }}
                          >
                            <ReactPlayer
                              url={proposal.Projects.media[0]}
                              width="300px"
                              height="288px"
                              playing={true}
                              loop={true}
                              volume={1}
                              muted={true}
                              controls={false}
                              className="react-player"
                            />
                            <div
                              className="gradient-overlay"
                              style={{
                                position: "absolute",
                                height: "288px",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background:
                                  "linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%)",
                              }}
                            />

                            <div
                              style={{
                                position: "relative",
                                bottom: "288px",
                              }}
                            >
                              <div className="title">
                                {proposal.Projects.name}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginLeft: "20px",
                              marginBottom: "10px",
                              zIndex: "1",
                            }}
                          >
                            <Avatar
                              className="avatar"
                              {...stringAvatar(
                                `${proposal.Projects.Users.first_name} ${proposal.Projects.Users.last_name}`
                              )}
                              src={proposal.Projects.Users.profile_picture}
                            />
                            <div className="owner">
                              {`${proposal.Projects.Users.first_name} ${proposal.Projects.Users.last_name}`}
                            </div>
                          </div>
                        </CardMedia>
                      )}
                    {proposal.Projects.media.length > 0 &&
                      !ReactPlayer.canPlay(proposal.Projects.media[0]) && (
                        <CardMedia
                          onClick={() =>
                            navigateToProposalPage(proposal.Projects.id)
                          }
                          className="card-media"
                          sx={{
                            background: `linear-gradient(
                          0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%
                        ), url(${proposal.Projects.media[0]}) lightgray 50% / cover no-repeat`,
                          }}
                          title="photoshoot"
                        >
                          <div>
                            <div className="title">
                              {proposal.Projects.name}{" "}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <Avatar
                              className="avatar"
                              {...stringAvatar(
                                `${proposal.Projects.Users.first_name} ${proposal.Projects.Users.last_name}`
                              )}
                              src={proposal.Projects.Users.profile_picture}
                            />
                            <div className="owner">
                              {`${proposal.Projects.Users.first_name} ${proposal.Projects.Users.last_name}`}
                            </div>
                          </div>
                        </CardMedia>
                      )}

                    {!proposal.Projects.media.length && (
                      <CardMedia
                        onClick={() =>
                          navigateToProposalPage(proposal.Projects.id)
                        }
                        className="card-media"
                        sx={{
                          background: `${colors[getNextColorIndex()]}`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                        title="photoshoot"
                      >
                        <div>
                          <div className="title">{proposal.Projects.name} </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Avatar
                            className="avatar"
                            {...stringAvatar(
                              `${proposal.Projects.Users.first_name} ${proposal.Projects.Users.last_name}`
                            )}
                          />
                          <div className="owner">{`${proposal.Projects.Users.first_name} ${proposal.Projects.Users.last_name}`}</div>
                        </div>
                      </CardMedia>
                    )}

                    <CardContent
                      sx={{ padding: "12px 12px 12px 12px", height: "45px" }}
                    >
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: proposal.Projects.description,
                        }}
                      ></div>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        className="project-card-btn"
                        onClick={() =>
                          navigateToProposalPage(proposal.Projects.id)
                        }
                      >
                        {t("manage")}
                      </Button>
                      {/* <IconButton aria-label="delete">
                        <DeleteIcon />
                      </IconButton> */}
                    </CardActions>
                  </Card>
                );
              })}

            {!inProgress && myProposals.length === 0 && (
              <Typography sx={{ fontWeight: "300", fontSize: "14px" }}>
                {t("noProposals")}
              </Typography>
            )}
            {inProgress &&
              Array.from({ length: 8 }).map((_, index) => (
                // Your JSX here. Use `index` if you need the iteration count.
                <Card key={index} className="card-project">
                  <CardMedia
                    sx={{ width: "300px", height: "288px" }}
                    title="photoshoot"
                  >
                    {" "}
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="100%"
                    ></Skeleton>
                  </CardMedia>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "10px 8px 0px 8px",
                      height: "110px",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Skeleton variant="text" width="80%"></Skeleton>
                    <Skeleton variant="text" width="80%"></Skeleton>
                  </Box>
                </Card>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyProposals;
