import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import Chip from "@mui/material/Chip";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import PersonIcon from "@mui/icons-material/Person";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import PostAddIcon from "@mui/icons-material/PostAdd";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import { supabase } from "./SupabaseClient";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DialogContentText from "@mui/material/DialogContentText";
import { useTranslation } from "react-i18next";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { loadStripe } from "@stripe/stripe-js";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  const nameParts = name.split(" ");
  const initials =
    nameParts.length > 1
      ? `${nameParts[0][0]}${nameParts[1][0]}`
      : `${nameParts[0][0]}`;

  return {
    sx: {
      bgcolor: stringToColor(name),
      cursor: "pointer",
    },
    children: initials.toUpperCase(),
  };
}

const ProposalManagement = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [project, setProject] = useState("");
  const [open, setOpen] = useState(false);
  const [memo, setMemo] = useState("");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDeleteProjectDialog, setOpenDeleteProjectDialog] = useState(false);
  const { t } = useTranslation();
  const [status, setStatus] = useState("");
  const [proposals, setProposals] = useState([]);
  const [hireManagerDialog, setHireManagerDialog] = useState(false);
  const handleMemo = (e) => {
    setMemo(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlePlayClick = async () => {
    if (project.type === "collab") {
      const { data: status, error: statusError } = await supabase
        .from("Projects")
        .update({ status: "live" })
        .eq("id", projectId);

      if (statusError) {
        console.log(statusError);
      } else {
        setStatus("live");
        return;
      }
    }
  };

  const getProject = async () => {
    const { data } = await supabase
      .from("Projects")
      .select(
        `
        *,
        Users (id, first_name, last_name, profile_picture)
      `
      )
      .eq("id", projectId);

    if (data) {
      setProject(data[0]);
    }
  };

  const getProposals = async () => {
    const { data } = await supabase
      .from("Proposals")
      .select("*, Users (first_name, last_name, profile_picture, id)")
      .eq("project_id", projectId);

    if (data) {
      setProposals(data);
    }
  };

  useEffect(() => {
    getProject();
    getProposals();
  }, []);

  const handleRemoveTeamMember = async (roleId, userId) => {
    const { data, error } = await supabase
      .from("ProjectRoles")
      .update({ user: null, filled: false })
      .eq("id", roleId);

    if (error) {
      console.log(error);
    } else {
      const { data: applications, error: applicationsError } = await supabase
        .from("Applications")
        .update({ approved: false })
        .eq("role", roleId)
        .eq("user", userId);
      if (applicationsError) {
        console.log(applicationsError);
      } else {
        getProject();
      }
    }
  };

  const handleHire = async () => {
    console.log("hire");
  };

  const handleDelete = async () => {
    const { data, error } = await supabase
      .from("Projects")
      .delete()
      .eq("id", projectId);

    if (error) {
      console.log(error);
    } else {
      navigate("/projects");
    }
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      {project ? (
        <Box
          className="custom-margins-submissions"
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "3rem",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <Box>
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  gap: "1rem",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ textAlign: "left", fontWeight: "300" }}
                >
                  {project.name}
                </Typography>
                <Tooltip title="Start Project" placement="right">
                  <IconButton
                    sx={{ color: "#9f7fe3" }}
                    onClick={handlePlayClick}
                  >
                    <PlayCircleFilledIcon />
                  </IconButton>
                </Tooltip>
              </div>

              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  padding: "0 !important",
                  margin: "0px !important",
                }}
              >
                <Typography mr={2} variant="body2">
                  {`${project.Users.first_name} ${project.Users.last_name}`}
                </Typography>

                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    navigate(`/editproject/${projectId}`);
                  }}
                >
                  <EditRoundedIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => setOpenDeleteProjectDialog(true)}
                >
                  <DeleteIcon />
                </IconButton>
                {/* Delete Project Confirmation Dialog */}
                <Dialog
                  open={openDeleteProjectDialog}
                  onClose={() => setOpenDeleteProjectDialog(false)}
                  aria-labelledby="confirm-dialog-title"
                  aria-describedby="confirm-dialog-description"
                >
                  <DialogTitle id="confirm-dialog-title">
                    {t("deleteProject")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                      {t("deleteProjectPrompt")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenDeleteProjectDialog(false)}>
                      {t("cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        handleDelete();
                        setOpenDeleteProjectDialog(false);
                      }}
                      color="primary"
                      autoFocus
                    >
                      {t("confirm")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Container>
            </Box>
            <Box sx={{ marginTop: "30px" }}>
              <Container
                sx={{
                  padding: "0px !important",
                  height: "100%",
                  margin: "0px !important",
                  maxWidth: "1280px !important",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    {t("proposals")}
                  </Typography>
                </Box>

                {proposals.map((proposal) => (
                  <div
                    key={proposal.id}
                    onClick={() =>
                      window.open(`/profile/${proposal.Users.id}`, "_blank")
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "160px",
                      padding: "20px",
                      gap: "12px",
                      backgroundColor: "#fff",
                      borderRadius: "12px",
                      boxShadow: "0 4px 6px rgba(0,0,0,0.06)",
                      margin: "10px 0",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      border: "1px solid rgba(0,0,0,0.08)",
                      "&:hover": {
                        transform: "translateY(-4px)",
                        boxShadow: "0 8px 16px rgba(0,0,0,0.08)",
                        borderColor: "rgba(0,0,0,0.12)",
                      },
                    }}
                  >
                    <Avatar
                      className="avatar"
                      {...stringAvatar(
                        `${proposal.Users.first_name} ${proposal.Users.last_name}`
                      )}
                      src={proposal.Users.profile_picture}
                      sx={{ width: "100px", height: "100px" }}
                    />
                    <div
                      style={{
                        fontSize: "16px",
                        color: "#333",
                        fontWeight: "300",
                      }}
                    >{`${proposal.Users.first_name} ${proposal.Users.last_name}`}</div>
                    <div
                      style={{
                        backgroundColor: "rgb(227 214 255)",
                        color: "#9f7fe3",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        fontSize: "10px",
                        fontWeight: "500",
                      }}
                    >
                      Proposal
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#ffffff",
                          color: "#000000",
                          textTransform: "none",
                          fontSize: "10px",
                          fontWeight: "400",
                          padding: "2px 2px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                          border: "0.5px solid #000000",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/message/${proposal.Users.id}`);
                        }}
                      >
                        Message
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#ffffff",
                          color: "#000000",
                          textTransform: "none",
                          fontSize: "10px",
                          fontWeight: "400",
                          padding: "2px 2px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                          border: "0.5px solid #000000",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setHireManagerDialog(true);
                        }}
                      >
                        Hire
                      </Button>
                    </div>
                  </div>
                ))}
                <Dialog
                  open={hireManagerDialog}
                  onClose={() => setHireManagerDialog(false)}
                  PaperProps={{
                    sx: {
                      padding: "10px", // Add padding to the entire dialog
                    },
                  }}
                >
                  <DialogTitle id="confirm-dialog-title">
                    {t("confirmHire")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                      {t("confirmHirePrompt")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      sx={{ fontSize: "10px !important" }}
                      onClick={() => setHireManagerDialog(false)}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        handleHire();
                        setHireManagerDialog(false);
                      }}
                      color="primary"
                      autoFocus
                      sx={{ fontSize: "10px !important" }}
                    >
                      {t("confirm")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Container>
            </Box>
          </Box>
        </Box>
      ) : (
        <Stack
          className="custom-margins"
          spacing={4}
          sx={{
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
        >
          <Skeleton variant="rounded" width={300} height={70} />
          <Skeleton variant="rounded" width={600} height={40} />

          <Skeleton variant="rounded" width={1280} height={300} />
        </Stack>
      )}
    </Box>
  );
};

export default ProposalManagement;
